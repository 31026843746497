<template>
  <div>
    <LoadingOverlay v-if="isFetching" />
    <div
      class="candidate-dashboard pb-4 container-lg col-lg-11"
      v-if="currentUserRole === Role.systemAdmin">
      <h4 class="mb-3 text-center text-primary pb-3">Admin Dashboard</h4>
      <CRow class="tile">
        <CCol
          sm="12"
          lg="3"
          v-for="({ id, label, value, apl_start_date, apl_end_date }, index) in getCandidateData"
          :key="index">
          <div class="card">
            <div class="card-body d-flex align-items-center p-3">
              <div
                class="mr-3 p-3 icon-wrapper"
                :style="getTheme(id).text_color">
                <i v-if="iconHide(id)" :class="getTheme(id).src" />
                <img v-else :src="getTheme(id).src" />
              </div>
              <div>
                <div class="text-value" :style="getTheme(id).text_color">
                  {{ value }}
                </div>
                <div class="text-muted font-weight-bold small">{{ label }}</div>
                <p v-if="id === 13" class="font-weight-bold small">{{ apl_start_date }} - {{ apl_end_date }}</p>
              </div>
            </div>
          </div>
        </CCol>
      </CRow>
      <CRow class="mb-2">
        <CCol lg="12" sm="12">
          <CCard>
            <CCardBody>
              <div class="header-container">
                <div class="align-center">
                  <h5 class="text-primary text-center text-nowrap">
                    Last {{ chartFilter.no_of_days.code }} Days
                  </h5>
                </div>
                <div class="align-right">
                  <div style="width: 150px">
                    <Select
                      name="no_of_days"
                      :options="noOfDaysOpt"
                      :value="chartFilter.no_of_days"
                      @change="handleChangeSelect"
                      :clearable="false" />
                  </div>
                </div>
              </div>
              <CChartBar
                :datasets="defaultDatasets"
                :labels="labels"
                :options="options"
                :plugins="plugins"
                style="height: 300px" />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </div>
    <CRow v-else class="py-2 px-4">
      <CCol
        sm="4"
        lg="2"
        v-for="({ id, label, value }, index) in getCandidateData"
        :key="index">
        <div class="stats text-primary">
          <i class="stats-icon" :class="getTheme(id).src"></i>
          <h2 class="count">{{ value }}</h2>
          <p class="text-center">{{ label }}</p>
        </div>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Select from "@/components/reusable/Fields/Select.vue";
import { Role, CANDIDATE_URL } from "@/helpers/helper";
import { CChartBar } from "@coreui/coreui-vue-chartjs";
import moment from "moment";
import Vue from "vue";
export default {
  name: "Dashboard",
  components: {
    Select,
    CChartBar,
  },
  data() {
    return {
      Role,
      CANDIDATE_URL,
      small: true,
      striped: false,
      bordered: true,
      isMobile: false,
      fields: [
        { key: "geography", label: "Geography" },
        // { key: "agency", label: "Agency" },
        { key: "physician", label: "Physician" },
        { key: "allied_health", label: "Allied Health" },
        {
          key: "allied_health_professional",
          label: "Pharmacist - Allied Health Professional",
        },
        { key: "dentist", label: "Dentist" },
        { key: "nurse", label: "Nurse" },
      ],
      options: {
        hover: {
          animationDuration: 0,
        },
        maintainAspectRatio: false,
        showAllTooltips: false,
        responsive: true,
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: false,
              },
            },
          ],
        },
        animation: {
          onComplete: function () {
            var chartInstance = this.chart,
              ctx = chartInstance.ctx;
            ctx.font = Chart.helpers.fontString(
              Chart.defaults.global.defaultFontSize,
              Chart.defaults.global.defaultFontStyle,
              Chart.defaults.global.defaultFontFamily
            );
            ctx.textAlign = "center";
            ctx.textBaseline = "bottom";
            this.data.datasets.forEach(function (dataset, i) {
              var meta = chartInstance.controller.getDatasetMeta(i);
              if (!meta.hidden)
                meta.data.forEach(function (bar, index) {
                  var data = dataset.data[index];
                  if (data) ctx.fillText(data, bar._model.x, bar._model.y - 5);
                });
            });
          },
        },
        tooltips: {
          yAlign: "top",
        },
      },
      plugins: [
        {
          beforeInit: function (chart, options) {
            chart.legend.afterFit = function () {
              this.height = this.height + 15;
            };
          },
        },
      ],
      labels: [],
      noOfDaysOpt: [
        { code: 7, label: "7 Days" },
        { code: 14, label: "14 Days" },
        { code: 30, label: "30 Days" },
        { code: 60, label: "60 Days" },
        { code: 90, label: "90 Days" },
        { code: 365, label: "1 Year" }

      ],
      chartFilter: { no_of_days: { code: 7, label: "7 Days" } },
      isFetching: false,
    };
  },
  computed: {
    ...mapGetters([
      "getCandidateByType",
      "getRoleScopes",
      "getJobTotalCount",
      "getTotalCount",
      "getRecruitedCandidateCount",
      "getRegisteredUserCount",
      "getCandidateCountList",
      "getJobApplicationCount",
      "getShortlistCount",
      "getApplicationCount",
      "getFacilitiesCount",
      "getDashboardAll",
    ]),
    candidateCounts() {
      return (
        this.labels.map((v) => {
          const date = moment(v, "DD-MM-YYYY").format("YYYY-MM-DD");
          const count =
            this.getCandidateCountList.find((v) => date === v?.date)?.count ||
            0;
          return count;
        }) || []
      );
    },
    jobCounts() {
      return (
        this.labels.map((v) => {
          const date = moment(v, "DD-MM-YYYY").format("YYYY-MM-DD");
          const count =
            this.getJobApplicationCount.find((v) => date === v?.date)?.count ||
            0;
          return count;
        }) || []
      );
    },
    defaultDatasets() {
      return [
        {
          label: "Candidates Registered",
          backgroundColor: "#00A1C9",
          data: this.candidateCounts,
          borderWidth: 0.5,
        },
        {
          label: "Job Applications",
          backgroundColor: "#EF820D",
          data: this.jobCounts,
          borderWidth: 0.5,
        },
      ];
    },
    currentUserRole() {
      return this.getRoleScopes.length ? this.getRoleScopes[0] : "";
    },
    getCandidateData() {
      if (this.getDashboardAll) {
        let getCandidateData = this.getDashboardAll.map((data) => {
          const getData = {};
          getData.value = data;
          return getData;
        });
        let totalPhysician = {
          id: 1,
          label: "Physician",
          value: this.getDashboardAll[0].physician || 0,
        };
        let totalDentist = {
          id: 4,
          label: "Dentist",
          value: this.getDashboardAll[0].dentist || 0,
        };
        let totalNurse = {
          id: 2,
          label: "Nurse/Midwife",
          value: this.getDashboardAll[0].nurse_or_midwife || 0,
        };
        let totalAHP = {
          id: 3,
          label: "Allied Health Professional",
          value: this.getDashboardAll[0].allied_health_professional || 0,
        };
        let totalJob = {
          id: 8,
          label: "Total Jobs Posted",
          value: this.getDashboardAll[0].total_jobs_posted || 0,
        };
        let totalCandidates = {
          id: 9,
          label: "Total Candidates",
          value: this.getDashboardAll[0].total_candidates || 0,
        };
        let totalRecruited = {
          id: 10,
          label: "Total Recruited",
          value: this.getDashboardAll[0].total_recruited || 0,
        };
        let totalRegistedUser = {
          id: 11,
          label: "Total Registered Users & Admins",
          value: this.getDashboardAll[0].total_registered_users || 0,
        };
        let totalFacilities = {
          id: 12,
          label: "Total Registered Facilities",
          value: this.getDashboardAll[0].total_registered_facilities || 0,
        };
        let totalApplications = {
          id: 13,
          label: "Total Applications Across All Jobs",
          value: this.getDashboardAll[0].total_applications || 0,
          apl_start_date: moment(this.getDashboardAll[0].total_applications_start_date).format("MMM YYYY") ,
          apl_end_date: moment(this.getDashboardAll[0].total_applications_end_date).format("MMM YYYY") ,
        };
        let totalShortListed = {
          id: 14,
          label: "Total Candidates Shortlisted",
          value: this.getDashboardAll[0].total_candidates_shortlisted || 0,
        };
        getCandidateData
          ? getCandidateData.unshift(
              totalPhysician,
              totalDentist,
              totalNurse,
              totalAHP,
              totalFacilities,
              totalRegistedUser,
              totalJob,
              totalApplications,
              totalShortListed,
              totalRecruited,
              totalCandidates
            )
          : [];
        getCandidateData.pop();
        let candidateData = _.filter(getCandidateData, (data) => {
          if (data.id != 6 && data.id != 7) return data;
        });
        // let agency = { id: 0, label: "Agency", value: "--" };
        // getCandidateData ? getCandidateData.push(agency) : [];
        const sortOrder = [13, 1, 4, 2, 3, 9, 14, 10];
        return candidateData.sort((a, b) => {
          return sortOrder.indexOf(a.id) - sortOrder.indexOf(b.id);
        });
      }
    },
    getItems() {
      let getItems = {};
      this.getCandidateData
        ? this.getCandidateData.map((data) => {
            data.id == 1 ? (getItems["physician"] = data.value) : null;
            data.id == 2 ? (getItems["nurse"] = data.value) : null;
            data.id == 3 ? (getItems["allied_health"] = data.value) : null;
            data.id == 4 ? (getItems["dentist"] = data.value) : null;
            data.id == 5
              ? (getItems["allied_health_professional"] = data.value)
              : null;
            data.id == 6 ? (getItems["agency"] = data.value) : null;
            getItems["geography"] = "--";
          })
        : null;
      return [getItems];
    },
  },
  mounted() {
    this.scrollTop();
    this.fetchDashboardAll();
    let isMobile = window.matchMedia(
      "only screen and (max-width: 760px)"
    ).matches;
    this.isMobile = isMobile;
    this.setUpChart();
  },
  methods: {
    ...mapActions([
      "FetchCandidateByType",
      "fetchJobTotalCount",
      "getTotalCandidates",
      "fetchRecruitedCandidateCount",
      "fetchRegisteredUserCount",
      "scrollTop",
      "fetchCandidateCount",
      "fetchJobApplicationCount",
      "fetchRegisteredFacilityCount",
      "fetchApplicationCount",
      "fetchShortListedCount",
      "fetchDashboardAll",
    ]),
    setUpChart(NUM_OF_DAYS = 7) {
      const start_range = moment()
        .subtract(NUM_OF_DAYS - 1, "d")
        .format("YYYY-MM-DD");
      const end_range = moment().format("YYYY-MM-DD");
      let appendAction = [];
      appendAction = [
        ...appendAction,
        this.fetchCandidateCount({
          start_range,
          end_range,
        }),
      ];
      appendAction = [
        ...appendAction,
        this.fetchJobApplicationCount({
          start_range,
          end_range,
        }),
      ];
      this.isFetching = true;
      Promise.all(appendAction).then((res) => {
        this.isFetching = false;
        const last7Days = [];
        for (let i = 0; i < NUM_OF_DAYS; i++) {
          let date = moment();
          date.subtract(i, "day");
          last7Days.push(date.format("DD-MM-YYYY"));
        }
        this.labels = last7Days;
      });
    },
    getTheme(id) {
      let src = "";
      let color = "";
      switch (id) {
        case 0:
          src = "fas fa-clinic-medical";
          color = "#35c792;";
          break;
        case 1:
          src = "fas fa-user-md";
          color = "#8a08a2;";
          break;
        case 2:
          src = "fas fa-user-nurse";
          color = "#61892F";
          break;
        case 3:
          src = "fas fa-notes-medical";
          color = "#1973e8";
          break;
        case 4:
          src = "fas fa-tooth";
          color = "#e01f66;";
          break;
        case 5:
          src = "fas fa-prescription-bottle-alt";
          color = "#B23850;";
          break;
        case 6:
          src = "fas fa-hospital";
          color = "#895061;";
          break;
        case 8:
          src = "img/job.png";
          color = "#8a08a2;";
          break;
        case 9:
          src = "img/group.png";
          color = "#dd3651;";
          break;
        case 10:
          src = "fas fa-user-tag";
          color = "#1973e8;";
          break;
        case 11:
          src = "fas fa-user-shield";
          color = "#61892F;";
          break;
        case 12:
          src = "fa-solid fa-hospital";
          color = "#8A08A2";
          break;
        case 13:
          src = "fa-solid fa-hospital-user";
          color = "#E01F66";
          break;
        case 14:
          src = "fa-solid fa-user-plus";
          color = "#1973E8";
          break;
        default:
          src = "fas fa-user-shield";
          color = "#A64AC9;";
      }
      return {
        src: src,
        bg_color: "background-color: " + color,
        color: "color: " + color,
        text_color: "color: " + color,
      };
    },
    handleChangeSelect(name, value) {
      Vue.set(this.chartFilter, name, value);
      if (name === "no_of_days" && value?.code) {
        this.setUpChart(value?.code);
      }
    },
    routeToCandidateList() {
      this.$router.push(this.CANDIDATE_URL);
    },
    iconHide(id) {
      if (id == 8 || id == 9) return false;
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  text-align: center;
  font-size: 22px;
  margin-top: 7px;
}

.bar-container {
  max-width: 570px;
  // max-height: 50vh;
  margin-left: 2px;
}
.search-label {
  white-space: nowrap;
}
.text-size {
  font-size: 14px;
}
@media only screen and (max-width: 760px) {
  .mobile-bar-container {
    width: 380px;
    height: 250px;
    margin-left: 2px;
    margin-right: 4px;
    margin-bottom: 7px;
  }
  .header {
    text-align: center;
    font-size: 18px;
    margin-top: 7px;
  }
}
@media only screen and (max-width: 968px) {
  .search-label {
    margin-top: 0px;
  }
}
@media only screen and (min-width: 768px) {
  .search-label {
    margin-top: 3px;
    width: 80%;
    text-align: left;
  }
}
@media only screen and (max-width: 480px) {
  .filter-col {
    flex: 0 0 98.33333%;
    max-width: 98.33333%;
  }
  .filter-label {
    margin: 10px;
  }
}
.header-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}
.align-right {
  margin-left: auto;
}

.align-center {
  margin-left: 50%;
  transform: translate(-50%);
}
</style>
